import {createContext, useContext, useReducer} from "react";

export const flashTypes = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
}

const FlashStateContext = createContext();
const FlashDispatchContext = createContext();
export const flashState = {
  show: false,
  title: '',
  message: '',
  level: flashTypes.info
};


export function useFlashState() {
  const context = useContext(FlashStateContext);
  if (context === undefined) {
    throw new Error('Использование useFlashState только внутри FlashProvider');
  }
  return context;
}

export function useFlashDispatch() {
  const context = useContext(FlashDispatchContext);
  if (context === undefined) {
    throw new Error('Использование useFlashDispatch только внутри FlashProvider');
  }
  return context;
}

export const FlashProvider = ({children}) => {
  const [flash, dispatchFlash] = useReducer(FlashReducer, flashState);

  return (
    <FlashStateContext.Provider value={flash}>
      <FlashDispatchContext.Provider value={dispatchFlash}>
        {children}
      </FlashDispatchContext.Provider>
    </FlashStateContext.Provider>
  );
};

export const FlashReducer = (flashState, action) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...flashState,
        title: action.payload.title,
        message: action.payload.message,
        level: action.payload.level,
        show: true,
      };
    case 'HIDE':
      return {
        ...flashState, show: false, title: '', message: ''
      };
    default:
      throw new Error(`Незадекларированный тип: ${action.type}`);
  }
};


export async function sendFlash(dispatch, message, level = 'info') {
  dispatch({type: "SHOW", payload: {"message": message, "level": level}})
  setTimeout(() => {
    dispatch({type: "HIDE", payload: {}})
  }, 5000)
}