import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {API_URL, TYPE_INPUT} from "../settings";
import ComponentCard from "./componentCard";
import Header from "./header";
import Nomenclature from "./nomenclature";
import {Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import classNames from "classnames";

function Body() {
  const params = useParams();
  const [configData, setConfigData] = useState(null);
  const [fullData, setFullData] = useState(null);
  const [categoryConfiguration, setCategoryConfiguration] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [productGroupOrder, setProductGroupOrder] = useState([]);
  const [nomenclature, setNomenclature] = useState(params.product);
  const [validNomenclature, setValidNomenclature] = useState(false);

  const [product] = useState(params.product);
  // Признак необходимости загрузить корзину с excnhange
  const [needDownload, SetNeedDownload] = useState(!!params.load);
  const [storageId] = useState(params.storage_id ? params.storage_id : "test");
  const [userId] = useState(params.user_id ? params.user_id : "testUser");

  const [editProcessing, setEditProcessing] = useState(false);

  useEffect(() => {
    if (editProcessing) {
      const result = [];
      Object.keys(configData).forEach(key => {
        configData[key].forEach(item => {
          result.push(item.name);
        });
      });
      setEditProcessing(false)
      setSelectedComponents(result)
    }
  }, [editProcessing]);

  function back_from_cart(data) {
    parseData(data)
  }


  async function parseData(param) {
    await handleReset()
    try {
      const response = await fetch(API_URL + 'get_components/' + product, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
      });
      const data = await response.json();
      await sortedConfig(data, false, true)
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(API_URL + product);
      const data = await response.json();
      if (!productGroupOrder.length)
        // Сохраняем порядок групп из выбранной "линейки продукции"
        setProductGroupOrder(data[product].map((component) => component.name))
      else
        await sortedConfig(data,
          true,
          false,
        );
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
  }, [product, productGroupOrder]);

  useEffect(() => {
    if (!nomenclature.includes("...") && nomenclature !== params.product) {
      setValidNomenclature(true);
    } else {
      setValidNomenclature(false);
    }
  }, [nomenclature, params.product]);

  useEffect(() => {
    fetchData();
  }, [product, fetchData]);

  function sortedConfig(
    data,
    flag_full = false,
    flagUpdateSelectedComponents = false,
  ) {
    if (flag_full) {
      // Запоминаем конфигурацию номенклатуры и её компонентов только при первоначальной загрузке
      let configuration = {};
      data[product].forEach((item) => {
        configuration[item.name] = item;
      });
      setCategoryConfiguration(configuration);
    }
    // Сохраняем порядок групп из выбранной "линейки продукции"
    // Формируем новый объект данных без группы "линейки продукции" и в нужном порядке
    const filteredData = {};
    productGroupOrder.forEach((group) => {
      if (group === product) return;
      filteredData[group] = data[group];
      if (data[group] === undefined) {
        filteredData[group] = []
      }
    });
    if (flag_full) setFullData(filteredData);
    if (flagUpdateSelectedComponents) {
      setConfigData(filteredData);
      setEditProcessing(true)
    } else
      setConfigData(filteredData);
  }

  function updateComponents(componentName) {
    const updatedComponents = selectedComponents.includes(componentName)
      ? selectedComponents.filter((component) => component !== componentName)
      : [...selectedComponents, componentName];
    setSelectedComponents(updatedComponents);
    return updatedComponents;
  }

  function getLastElements(availableElements, updatedComponents) {
    // Получение всех уникальных элементов в группе
    let result = [];
    var keys = Object.keys(availableElements);
    keys.forEach(function (key) {
      if (availableElements[key].length === 1) {
        result.push(availableElements[key][0].name);
      }
    });
    const finalComponents = Array.from(
      new Set([...updatedComponents, ...result])
    );
    console.log("finalComponents", finalComponents);
    return finalComponents;
  }

  async function handleComponentSelect(componentName) {
    try {
      // Добавляем или удаляем выбранный компонент в зависимости от его текущего состояния

      const updatedComponents = updateComponents(componentName);

      const bodyPost = JSON.stringify({
        selected: updatedComponents,
      });
      console.log("Тело запроса post:", bodyPost);

      // Отправляем POST запрос с обновленным списком выбранных компонентов
      const response = await fetch(API_URL + product, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyPost,
      });

      const newData = await response.json();
      sortedConfig(newData);

      // После получения новой конфигурации отправляем запрос на получение
      // строкового представления текущей номенклатуры.
      // Осуществляется формирование уникальных элементов вне зависимости от выбора пользователя
      const responseNomenclature = await fetch(
        API_URL + "get_nomenclature/" + product,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selected: getLastElements(newData, updatedComponents),
          }),
        }
      );
      setNomenclature(await responseNomenclature.json());
    } catch (error) {
      console.error("Ошибка выбора компонента:", error);
    }
  }

  function checkGroupVisibility(components) {
    if (
      components.length !== 1 &&
      Object.keys(TYPE_INPUT).includes(components[0].type)
    ) {
      console.log("не отображать", components);
      return true;
    } else {
      return false;
    }
  }

  function handleReset() {
    setSelectedComponents([]);
    fetchData(); // Обновляем данные после сброса
  }

  const escClick = (event) => {
    if (event.key === "Escape") handleReset();
  };

  function defineGroupColor(components) {
    const if_selected_list = [];

    components.forEach((component) => {
      //проходимся по компонентам и добавляем в список if_selected_list значения true/false в зависимости от того, был ли выбран данный компонент
      if_selected_list.push(selectedComponents.includes(component.name));
    });

    //проверка того, что в группе компонентов сделан выбор или поле input заполнено
    const if_selected =
      if_selected_list.includes(true) || if_selected_list.length === 1
        ? true
        : false;

    //определение класса для группы
    const classNameStatus = {
      group_card_selected: if_selected,
      group_card: !if_selected,
    };
    return classNameStatus;
  }

  return (
    <div className="body-container" tabIndex="0" onKeyDown={escClick}>
      <Header handleReset={handleReset} product={product}/>
      <Nomenclature
        nomenclature={nomenclature}
        storageId={storageId}
        needDownload={needDownload}
        disableDownload={SetNeedDownload}
        userId={userId}
        isValid={validNomenclature}
        product={product}
        setEditNomenclature={back_from_cart}
        selectedComponents={selectedComponents}
      />
      {configData &&
        Object.entries(configData).map(([group, components]) => {
          return (
            <div key={group}>
              {!checkGroupVisibility(components) && (
                <div
                  key={group}
                  className={classNames(defineGroupColor(components))}
                >
                  <h2 className="group-title">
                    {group}
                    {categoryConfiguration[group].description && (
                      <Tooltip
                        title={categoryConfiguration[group].description}
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          className="descriptionIcon"
                        />
                      </Tooltip>
                    )}
                  </h2>
                  <ComponentCard
                    components={components}
                    selectedComponents={selectedComponents}
                    handleComponentSelect={handleComponentSelect}
                    fullComponents={fullData[group]}
                    categoryConfiguration={categoryConfiguration[group]}
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default Body;
