import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import userManual from "../userManual.md";
import { API_URL } from "../settings";

const UserManual = () => {
  const [markdown, setMarkdown] = useState("");
  const linkApi = API_URL + "static/manual/";

  function get_image_url(alt) {
    return `${linkApi}${alt}.png`
  }

  useEffect(() => {
    fetch(userManual)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  const components = {
    img: ({ alt }) => <img src={get_image_url(alt)} alt={alt} />,
  };

  return (
    <div className="markdown-container">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeSlug]}
        components={components}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

export default UserManual;
