import {Fragment, React, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from "@mui/material";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import NomenclatureList from "./nomenclatureList";
import uuid from "react-uuid";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {
  flashTypes,
  sendFlash,
  useFlashDispatch
} from "../context/flashProvider";
import * as XLSX from "xlsx";
import {API_URL, EMAIL_ENABLE} from "../settings";

function NomenclatureCartItem({
                                nomenclature,
                                selectedNomenclatures,
                                setSelectedNomenclatures,
                                setEditNomenclature
                              }) {
  const dispatchFlash = useFlashDispatch();

  function updateCountNomenclature(nomenclature) {
    nomenclature.count += 1;
    if (nomenclature.options.length > 0) {
      nomenclature.inputValues.push({
        id: uuid(),
        value: JSON.parse(JSON.stringify(nomenclature.options)),
      });
    }
    console.log("nomenclatureCount", nomenclature);
    setSelectedNomenclatures([...selectedNomenclatures]);
  }

  function delNomenclature() {
    setSelectedNomenclatures(
      selectedNomenclatures.filter((a) => a.id !== nomenclature.id)
    );
  }

  function editNomenclature() {
    setEditNomenclature(nomenclature)
    setSelectedNomenclatures(
      selectedNomenclatures.filter((a) => a.id !== nomenclature.id)
    );
  }

  function editCopyNomenclature() {
    setEditNomenclature(nomenclature)
  }

  function addEmptyInputValues(count) {
    nomenclature.count = count;
    nomenclature.inputValues = [];
    for (let i = 0; i < count; i++) {
      nomenclature.inputValues.push({
        id: uuid(),
        value: JSON.parse(JSON.stringify(nomenclature.options)),
      });
    }
    console.log(nomenclature.inputValues);
  }

  function parseInputValues(inputValues) {
    let excelInputs = [];
    inputValues.forEach((value) => {
      let inputs = [];
      value.value.forEach((element) => {
        if (element.is_category) {
          element.fields?.forEach((field) => {
            inputs.push({
              name: field.name,
              userValue: field.userValue ? field.userValue : "",
            });
          });
        } else {
          inputs.push({
            name: element.name,
            userValue: element.userValue ? element.userValue : "",
          });
        }
      });
      excelInputs.push(inputs);
    });

    return excelInputs;
  }

  function parseJsonExcelValue(json) {
    console.log(json, "Parse Json");
    addEmptyInputValues(json.length);
    nomenclature.inputValues.forEach((value, number) => {
      value.value.forEach((element) => {
        if (element.is_category) {
          element.fields?.forEach((field) => {
            field.userValue = json[number][field.name];
          });
        } else {
          element.userValue = json[number][element.name];
        }
      });
    });
    console.log(nomenclature.inputValues, "Значения после заполнения из Excel");
    setSelectedNomenclatures([...selectedNomenclatures]);
  }

  function replaceSymbol(string, download) {
    const replacements = {
      "*": "@",
      "<": "{",
      ">": "}",
      "|": "$",
      "/": "_",
    };

    const reverseReplacements = Object.fromEntries(
      Object.entries(replacements).map(([key, value]) => [value, key])
    );

    const currentReplacements = download ? replacements : reverseReplacements;

    for (const [key, value] of Object.entries(currentReplacements)) {
      string = string.replaceAll(key, value);
    }

    return string;
  }

  function handleChange(event) {
    console.log("onChange");
    if (event.target.files) {
      const fileName = replaceSymbol(
        event.target.files[0].name.slice(0, nomenclature.nomenclature.length),
        false
      );
      console.log(fileName);
      if (fileName.includes(nomenclature.nomenclature)) {
        uploadExcel(event);
      } else {
        console.log("The file name does not match the nomenclature");
        sendFlash(
          dispatchFlash,
          "Файл не относится к выбранной номенклатуре! Выберите подходящий файл.",
          flashTypes.warning
        );
        resetTargetValue(event);
      }
    }
  }

  function resetTargetValue(e) {
    e.target.value = "";
  }

  function uploadExcel(e) {
    console.log("Upload Excel");
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log(data);
        const workbook = XLSX.read(data, {type: "array"});
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        parseJsonExcelValue(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      sendFlash(
        dispatchFlash,
        "Файл успешно загружен. Корзина обновлена.",
        flashTypes.success
      );
    }
  }

  function downloadExcel(nomenclature) {
    const data = parseInputValues(nomenclature.inputValues);
    const wsData = [];
    const workbook = XLSX.utils.book_new();
    const headers = data[0].map((item) => item.name);
    const rows = data.map((item) => item.map((row) => row.userValue));
    wsData.push(headers);
    rows.forEach((row) => {
      wsData.push(row);
    });
    const worksheet = XLSX.utils.aoa_to_sheet(wsData, {origin: "A1"});
    XLSX.utils.book_append_sheet(workbook, worksheet, "List1");
    XLSX.writeFile(
      workbook,
      `${replaceSymbol(nomenclature.nomenclature, true)}.xlsx`
    );
    sendFlash(
      dispatchFlash,
      'Файл excel сформирован. Проверьте папку "Загрузки"',
      flashTypes.success
    );
  }

  return (
    <Box className={"cart-item"}>
      <NomenclatureList
        nomenclature={nomenclature}
        setSelectedNomenclatures={setSelectedNomenclatures}
        selectedNomenclatures={selectedNomenclatures}
      />
      <Box className={"cart-item-button"}>
        <IconButton onClick={() => updateCountNomenclature(nomenclature)}>
          <AddCircleOutlineIcon/>
        </IconButton>
        {nomenclature.options.length > 0 && (
          <PopupState variant="popover">
            {(popupState) => (
              <Fragment>
                <Tooltip title="Работа в Excel" arrow placement="top">
                  <IconButton variant="contained" {...bindTrigger(popupState)}>
                    <ImportExportIcon/>
                  </IconButton>
                </Tooltip>
                <Menu
                  {...bindMenu(popupState)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    component="label"
                    onClick={(e) => resetTargetValue(e)}
                  >
                    <input
                      hidden
                      id="upload-file"
                      type="file"
                      accept=".xlsx"
                      onChange={(e) => handleChange(e)}
                    />
                    <span>Загрузить из Excel</span>
                  </MenuItem>
                  <MenuItem onClick={() => downloadExcel(nomenclature)}>
                    Редактировать в Excel
                  </MenuItem>
                </Menu>
              </Fragment>
            )}
          </PopupState>
        )}
        <Tooltip title="Вернуть для редактирования" arrow placement="top">
          <IconButton onClick={() => editNomenclature()}>
            <EditIcon/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Редактировать копию" arrow placement="top">
          <IconButton onClick={() => editCopyNomenclature()}>
            <ContentCopyIcon/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Удалить" arrow placement="top">
          <IconButton onClick={() => delNomenclature()}>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>

      </Box>
    </Box>
  );
}

export default function NomenclatureCart({
                                           active,
                                           selectedNomenclatures,
                                           editNomenclature,
                                           setSelectedNomenclatures,
                                           setEditNomenclature
                                         }) {
  const [activeStatus, setActiveStatus] = useState(!active ? "hidden" : "");

  useEffect(() => {
    setActiveStatus(!active ? "hidden" : "");
    console.log(setEditNomenclature)
  }, [active]);

  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    nomenclatures: selectedNomenclatures
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Проверка валидности формы
    const isValid = Object.values(errors).every(error => !error) &&
      Object.values(formData).every(value => value !== '');
    setIsFormValid(isValid);
  }, [errors, formData]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    let isValid = true;

    if (name === 'firstName' || name === 'lastName') {
      isValid = /^[A-Za-zА-Яа-я]*$/.test(value);
    } else if (name === 'phone') {
      isValid = /^[0-9]*$/.test(value);
    } else if (name === 'email') {
      isValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
    }

    setFormData({...formData, [name]: value});
    setErrors({...errors, [name]: !isValid});
  };

  const handleSendData = () => {
    if (isFormValid) {
      setOpen(false);
      sendDataForEmail(); // Ваш метод для отправки данных
    } else {
      alert('Пожалуйста, исправьте ошибки в форме.');
    }
  };

  const bodyPost = JSON.stringify({
    nomenclatures: formData,
  });

  function sendDataForEmail() {
    try {
      fetch(`${API_URL}exchange/email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyPost,
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error("Ошибка отправки данных", error);
      });

    } catch (error) {
      console.error("Ошибка отправки данных", error);
    }
  }

  return (
    <div className={"cart " + activeStatus}>
      {selectedNomenclatures.length !== 0 ? (
        Array.from(selectedNomenclatures).map((nomenclature) => {
          return (
            <NomenclatureCartItem
              key={nomenclature.id}
              nomenclature={nomenclature}
              selectedNomenclatures={selectedNomenclatures}
              editNomenclature={editNomenclature}
              setSelectedNomenclatures={setSelectedNomenclatures}
              setEditNomenclature={setEditNomenclature}
            />
          );
        })
      ) : (
        <div className={"cart-item-name"}>
          В корзине отсутствуют номенклатуры
        </div>
      )}
      <div style={{paddingBottom: '10px'}}>
        {selectedNomenclatures.length !== 0 && EMAIL_ENABLE ? (
          <>
            <Button variant="contained" size="small" onClick={() => setOpen(true)}>
              <p style={{marginRight: '5px'}}>Отправить заявку</p>
              <BorderColorRoundedIcon></BorderColorRoundedIcon>
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Заполните данные</DialogTitle>
              <DialogContent>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  name="firstName"
                  label="Имя"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                  helperText={errors.firstName ? 'Только буквы' : ''}
                />
                <TextField
                  required
                  margin="dense"
                  name="lastName"
                  label="Фамилия"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                  helperText={errors.lastName ? 'Только буквы' : ''}
                />
                <TextField
                  required
                  margin="dense"
                  name="phone"
                  label="Телефон"
                  type="tel"
                  fullWidth
                  variant="standard"
                  value={formData.phone}
                  onChange={handleChange}
                  error={errors.phone}
                  helperText={errors.phone ? 'Только цифры' : ''}
                />
                <TextField
                  required
                  margin="dense"
                  name="email"
                  label="Почта"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email}
                  helperText={errors.email ? 'Введите корректную почту' : ''}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Отмена</Button>
                <Button onClick={() => handleSendData()} disabled={!isFormValid}>Отправить</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </div>
    </div>
  );
}
