import ListElement from "./listElement";

export default function ComponentCard({
  components,
  selectedComponents,
  handleComponentSelect,
  fullComponents,
  categoryConfiguration,
}) {
  const nameComponents = components.map((item) => item.name);
  const last_available_element = components.length === 1;

  return (
    <div className="component-card">
      <ul className="component-list">
        {fullComponents.map((component, index) => {
          const status_active = nameComponents.includes(component.name);
          const multiply_category = categoryConfiguration.type === "MULTIPLY";
          const status_selected = selectedComponents.includes(component.name);
          return (
            <div key={index}>
                <ListElement
                    component={component}
                    index={index}
                    status_selected={status_selected}
                    last_available_element={last_available_element}
                    multiply_category={multiply_category}
                    status_active={status_active}
                    handleComponentSelect={handleComponentSelect}
                  />
            </div>
          );
        })}
      </ul>
    </div>
  );
}
