import React from "react";
import {createRoot} from "react-dom/client";
import "./assets/index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {FlashProvider} from "./context/flashProvider";
import FlashMessage from "./components/flashMessage";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <FlashProvider>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    <FlashMessage/>
  </FlashProvider>
);
