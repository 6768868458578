import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { IconButton, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  flashTypes,
  sendFlash,
  useFlashDispatch,
} from "../context/flashProvider";

export default function MenuExportImport({
  onImport,
  selectedNomenclatures,
  product,
}) {
  const dispatchFlash = useFlashDispatch();

  function export_nomenclature() {
    if (selectedNomenclatures.length < 1) {

    } else {
      const reader = new FileReader();
      const nomenclaturesList = JSON.stringify(selectedNomenclatures);
      const file = new Blob([nomenclaturesList], { type: "text/plain" });
      reader.onload = (e) => {
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${product}.json`;
        a.click();

        URL.revokeObjectURL(url);
      };
      reader.readAsText(file);
      sendFlash(
        dispatchFlash,
        'Файл сформирован. Проверьте папку "Загрузки"',
        flashTypes.success
      );
    }
  }

  function importNomenclature() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = JSON.parse(e.target.result);
          onImport(data);
        };
        reader.readAsText(file);
      }
    };
    input.click();
  }
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <Tooltip title="Доп. опции" arrow placement="top">
            <div className={"controller"}>
              <IconButton variant="contained" {...bindTrigger(popupState)}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div onClick={importNomenclature}>
              <MenuItem onClick={popupState.close}>Импорт JSON</MenuItem>
            </div>
            <div onClick={export_nomenclature}>
              <MenuItem onClick={popupState.close}>Экспорт JSON</MenuItem>
            </div>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
