import { Badge, IconButton, Tooltip } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import NomenclatureCart from "./nomenclatureCart";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import MenuExportImport from "./menuExportImport";
import { API_URL } from "../settings";
import {
  flashTypes,
  sendFlash,
  useFlashDispatch,
} from "../context/flashProvider";

export default function Nomenclature({
  nomenclature,
  storageId,
  needDownload,
  disableDownload,
  userId,
  isValid,
  product,
  setEditNomenclature,
  selectedComponents
}) {
  const dispatchFlash = useFlashDispatch();
  const [cartOpen, setCartOpen] = useState(false);
  const [additionStyle, setAdditionStyle] = useState("valid");
  const [selectedNomenclatures, setSelectedNomenclatures] = useState(
    localStorage.getItem(product)
      ? JSON.parse(localStorage.getItem(product))
      : []
  );

  const handleImport = (data) => {
    setSelectedNomenclatures([...selectedNomenclatures, ...data]);
  };

  useEffect(() => {
    console.log("cartOpen", cartOpen);
  }, [cartOpen]);

  useEffect(() => {
    if (selectedNomenclatures !== JSON.parse(localStorage.getItem(product))) {
      console.log("Nomenclature save Storage");

      localStorage.setItem(product, JSON.stringify(selectedNomenclatures));
    }
    if (!needDownload){
    try {
      fetch(API_URL + "exchange/" + storageId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nomenclatures: selectedNomenclatures,
          userId: userId,
        }),
      })
        .then((response) => response.json())
        .then((json) => console.log("Upload", json));
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }}
    else {
      disableDownload(!needDownload)
      try {
      fetch(API_URL + "exchange/" + storageId, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("Download", json)
          setSelectedNomenclatures(json['nomenclatures'])
        });
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
    }
  }, [selectedNomenclatures, product, storageId, userId]);

  useEffect(() => {
    setAdditionStyle(isValid ? "valid" : "invalid");
  }, [isValid]);

  function createInputValue(options) {
    return [
      {
        id: uuid(),
        value: JSON.parse(JSON.stringify(options)),
      },
    ];
  }

  function addNomenclature() {
    try {
      fetch(API_URL + "get_inputs/" + product, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nomenclature: nomenclature,
          selected: selectedComponents
        }),
      })
        .then((response) => response.json())
        .then((options) =>
          setSelectedNomenclatures([
            ...selectedNomenclatures,
            {
              id: uuid(),
              nomenclature: nomenclature,
              count: 1,
              inputValues: createInputValue(options),
              options: options,
            },
          ])
        );
    } catch (error) {
      console.error("Ошибка получения опций номенклатуры", error);
    }
  }

  function handleClickAdd() {
    addNomenclature();
    sendFlash(dispatchFlash, "Сохранено", flashTypes.success);
  }

  return (
    <div className={"nomenclature"}>
      <NomenclatureCart
        active={cartOpen}
        selectedNomenclatures={selectedNomenclatures}
        setSelectedNomenclatures={setSelectedNomenclatures}
        setEditNomenclature={setEditNomenclature}
      />
      <div className={"currentNomenclature " + additionStyle}>
        <h1>{nomenclature}</h1>
        <div className={"flex"}>
          {isValid && (
            <Tooltip title="Добавить в корзину" arrow placement="top">
              <div className={"controller"} onClick={() => handleClickAdd()}>
                <IconButton>
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}

          <Tooltip title="Корзина" arrow placement="top">
            <div
              className={"controller"}
              onClick={() => setCartOpen(!cartOpen)}
            >
              <Badge
                badgeContent={selectedNomenclatures.length}
                showZero
                color="primary"
              >
                <IconButton>
                  <ShoppingCartIcon />
                </IconButton>
              </Badge>
            </div>
          </Tooltip>
          <MenuExportImport
            onImport={handleImport}
            selectedNomenclatures={selectedNomenclatures}
            product={product}
          />
        </div>
      </div>
    </div>
  );
}
