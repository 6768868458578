import { Box, ListItemText, TextField } from "@mui/material";
import { TYPE_INPUT } from "../settings";

export default function ItemOptions({
  item,
  setSelectedNomenclatures,
  selectedNomenclatures,
}) {
  const sxListItemText = { marginLeft: "10px" };

  let type = "";
  let helperText = "";
  let minValue = null;
  let maxValue = null;
  let maxLength = null;

  switch (item.type) {
    case "STRING":
      type = "text";
      maxLength = item.validators?.maximum_length;
      helperText = `Введите строку (макс. ${maxLength})`;
      break;
    case "INT":
    case "FLOAT":
      type = "number";
      minValue = item.validators?.minimum_value?.replace(",", ".");
      maxValue = item.validators?.maximum_value?.replace(",", ".");
      helperText = minValue ? `Введите от ${minValue} до ${maxValue}` : "";
      break;
    default:
      break;
  }

  const inputProps = {
    step: TYPE_INPUT[item.type]?.step,
    min: minValue ? minValue : "",
    max: maxValue ? maxValue : "",
    maxLength: maxLength,
  };
  const handleChangeUserInput = (event) => {
    const value = event.target.value;
    item.userValue = value;
    console.log(item);
    setSelectedNomenclatures([...selectedNomenclatures]);
  };

  function checkError() {
    let error = false;

    if (item.userValue) {
      if (type === "text") {
        if (item.userValue.length > maxLength) {
          error = true;
        }
      } else if (maxValue && minValue) {
        const value = Number(parseFloat(item.userValue));
        error = !(value <= maxValue && value >= minValue);
      }
    }
    return error;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
      <ListItemText sx={sxListItemText} primary={item.name} />
      <TextField
        error={checkError()}
        helperText={helperText}
        id="standard-number"
        type={type}
        inputProps={inputProps}
        size="small"
        onChange={handleChangeUserInput}
        value={item.userValue ? item.userValue : ""}
      />
    </Box>
  );
}
