import React from "react";
import Body from "./components/body";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";

function App() {
  return (
    <Routes>
      <Route path="/:product/:storage_id?/:user_id?/:load?" element={<Body />} />
      <Route path="/:product/:storage_id?/:user_id?" element={<Body />} />
      <Route path="*" element={<Header />} />
    </Routes>
  );
}

export default App;
