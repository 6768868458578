// Файл настроек, содержащий URL для запросов к API
export const API_URL = "./backend/" ;
export const EMAIL_ENABLE = 1;
// export const API_URL = `http://localhost:5000/`;


export const TYPE_INPUT = {
  INT: { step: 1 },
  STRING: { len: 32 },
};
