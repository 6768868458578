import * as React from "react";
import { useFlashState } from "../context/flashProvider";
import { Alert } from "@mui/material";

function FlashMessage() {
  const flash = useFlashState();

  if (flash.show) {
    return (
      <Alert
        severity={flash.level}
        style={{
          position: "fixed",
          top: "4%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        {flash.message}
      </Alert>
    );
  }
}

export default FlashMessage;
