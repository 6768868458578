import {
  AppBar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { API_URL } from "../settings";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserManual from "./userManual";

export default function Header({ handleReset, product }) {
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(
    product ? product : ""
  );

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((json) => setAllProducts(json.map((product) => product.name)));
  }, []);

  useEffect(() => {
    navigate(`/${selectedProduct}`, { replace: true });
  }, [selectedProduct, navigate]);

  useEffect(() => {
    console.log("allProducts", allProducts);
  }, [allProducts]);

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: { xs: "none", sm: "block" },
                marginRight: "20px",
              }}
              className="configurator"
              onClick={() => navigate("/")}
            >
              Главная страница
            </Typography>
          </Box>
          {product ? (
            <></>
          ) : (
            <FormControl>
              <InputLabel id="select-product-label">
                Линейка продукции
              </InputLabel>
              <Select
                sx={{ minWidth: 240 }}
                id="select-product-label"
                label="Линейка продукции"
                onChange={handleChange}
              >
                {allProducts.map((product, key) => {
                  return (
                    <MenuItem key={key} value={product}>
                      {product}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {handleReset ? (
            <Box>
              <Button variant="contained" size="small" onClick={handleReset}>
                Сброс
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
      {!product && <UserManual />}
    </Box>
  );
}
