import List from "@mui/material/List";
import {
  FormControlLabel,
  ListSubheader,
  Radio,
  RadioGroup,
} from "@mui/material";
import ItemOptions from "./itemOptions";

export default function NestedNomenclature({
  inputValue,
  setSelectedNomenclatures,
  selectedNomenclatures,
}) {
  function addUserInput(event, item) {
    item.userValue = event.target.value;
    setSelectedNomenclatures([...selectedNomenclatures]);
  }

  function renderList(item, isCategory, key) {
    if (isCategory && item.type === "SELECT") {
      return (
        <List
          sx={{
            width: "100%",
          }}
          key={key}
          component="div"
          disablePadding
          subheader={isCategory ? subheader(item.name) : ""}
        >
          <RadioGroup
            row
            sx={{ justifyContent: "space-evenly" }}
            onChange={(event) => {
              addUserInput(event, item);
            }}
            value={item.userValue? item.userValue: ""}
          >
            {item.fields?.map((field, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={field.name}
                  control={<Radio />}
                  label={field.name}
                />
              );
            })}
          </RadioGroup>
        </List>
      );
    } else {
      return (
        <List
          sx={{
            width: "100%",
          }}
          key={key}
          component="div"
          disablePadding
          subheader={isCategory ? subheader(item.name) : ""}
        >
          {item.fields?.map((field, index) =>
            field.is_category ? (
              renderList(field, true, index)
            ) : (
              <ItemOptions
                key={index}
                item={field}
                setSelectedNomenclatures={setSelectedNomenclatures}
                selectedNomenclatures={selectedNomenclatures}
              />
            )
          )}
          {!isCategory && (
            <ItemOptions
              setSelectedNomenclatures={setSelectedNomenclatures}
              selectedNomenclatures={selectedNomenclatures}
              item={item}
            />
          )}
        </List>
      );
    }
  }

  function subheader(name) {
    return (
      <ListSubheader sx={{ textAlign: "center" }} component="div">
        {name}
      </ListSubheader>
    );
  }

  return inputValue.value.map((element, key) => {
    return renderList(element, element.is_category, key);
  });
}
