import { Tooltip } from "@mui/material";
import classNames from "classnames";
import parse from "html-react-parser";
import { API_URL } from "../settings";

export default function ListElement({
  component,
  index,
  status_selected,
  last_available_element,
  multiply_category,
  status_active,
  handleComponentSelect,
}) {
  const classNameStatus = {
    selected: status_selected || (last_available_element && !multiply_category),
    active: (status_active && !status_selected) || multiply_category,
    block: !status_active && !multiply_category,
    lastElement: !status_selected && last_available_element && status_active,
  };
  const imageUrl = component.photo
    ? `${API_URL}static/${component.photo}`
    : null;
  return (
    <Tooltip
      key={index}
      title={component.description ? component.description : ""}
      arrow
    >
      <li
        key={component.id}
        onClick={() => handleComponentSelect(component.name)}
        className={classNames(classNameStatus)}
      >
        <p className="p-0">{parse(component.name)}</p>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={component.name}
            className="component-photo"
          />
        )}
      </li>
    </Tooltip>
  );
}
