import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Divider, IconButton, ListItem, Typography } from "@mui/material";
import NestedNomenclature from "./nestedNomenclature";
import DeleteIcon from "@mui/icons-material/Delete";

export default function NomenclatureList({
  nomenclature,
  setSelectedNomenclatures,
  selectedNomenclatures,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  function delItemNomenclature(item) {
    nomenclature.count -= 1;
    nomenclature.inputValues = nomenclature.inputValues.filter(
      (a) => a.id !== item.id
    );
    setSelectedNomenclatures([...selectedNomenclatures]);
    console.log(nomenclature);
  }

  const renderNestedNomenclature = () => {
    if (nomenclature.options.length > 0) {
      return nomenclature.inputValues.map((inputValue, key) => {
        return (
          <ListItem key={key} sx={{ display: "list-item", marginLeft: "40px"}}>
            <Box sx={{ display: "flex" }}>
              <IconButton onClick={() => delItemNomenclature(inputValue)}>
                <DeleteIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom>
                {inputValue.id}
              </Typography>
            </Box>
            <Divider />
            <NestedNomenclature
              inputValue={inputValue}
              setSelectedNomenclatures={setSelectedNomenclatures}
              selectedNomenclatures={selectedNomenclatures}
            />
          </ListItem>
        );
      });
    } else {
      return (
        <Typography variant="h6" gutterBottom>
          {nomenclature.count}
        </Typography>
      );
    }
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: "50%",
      }}
      component="nav"
    >
      <ListItemButton onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary={nomenclature.nomenclature} />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {renderNestedNomenclature()}
      </Collapse>
    </List>
  );
}
